/**
 * @module
 * models for POIs
 */

import $ from 'jquery';
import {categories} from './categories.js';

// ---- classes ----

/**
 * Single POI
 */
class POI{
    
    constructor(id, location, category, tags){
	// id in source
	this.id = id;
	// location in WGS84 lon, lat (x,y)
	this.location = location;
	// category reference
	this.category = category;
	// OSM tags
	this.tags = tags;
    }

    /**
     * @return title, if any
     */
    get_title(){
	return this.tags.name;
    }

    /**
     * @return tags as key,value pairs
     */
    tagItems(){
	return Object.entries(this.tags);
    }
}

/**
 * POI request
 */
class Request{

    constructor(data, query, callback, rate_warning_callback, error_callback){
	// AJAX data
	this.data = data;
	// query used to create data
	this.query = query;
	// function to call on ready
	this.callback = callback;
	this.rate_warning_callback = rate_warning_callback;
	this.error_callback = error_callback;
	// AJAX XHR request, if any
	this.xhr = null;
	// cancelled status
	this.cancelled = false;
    }

    /**
     * @effect send request to server, calling callback and rate_ok_callback
     *         on done, or rate_error_callback if too frequent calls are done
     */
    send(rate_ok_callback, rate_error_callback){
	// run query
	this.xhr = $.ajax(this.data);
	
	this.xhr.done((json)=>{
	    var pois = [];
	    for(var element of json.elements){
		pois.push(this.jsonToPoi(element));
	    }
	    rate_ok_callback(this);
	    this.callback(this, pois);
	})
	this.xhr.fail((xhr)=>{
	    // ignore aborted calls
	    if(xhr.readyState == 0){
		return;
	    }
	    if(xhr.status == 429){
		rate_error_callback(this);
		this.rate_warning_callback(this);
	    }
	    else{
		this.error_callback(this);
	    }
	})
    }

    /**
     * @return JSON data in element as POI object
     */
    jsonToPoi(element){
	var p;
	if(element.lat !== undefined){
	    // plain point
	    p = [element.lon, element.lat]
	}
	else{
	    // way or relation, use bounds
	    p = [
		(element.bounds.maxlon + element.bounds.minlon) / 2,
		(element.bounds.maxlat + element.bounds.minlat) / 2,
	    ]
	}
	var category = categories.byTags(element.tags, this.query.categories);
	return new POI(element.id, p, category, element.tags);
    }
    
    /**
     * @effect mark request as cancelled, cancel any sent xhr request
     */
    abort(){
	this.cancelled = true;
	if(this.xhr){
	    this.xhr.abort();
	}
    }
}

/**
 * Queue of POI requests
 */
class RequestQueue{
    
    constructor(){
	// timestamp in ms for last call
	this.last_call = undefined;
	// the queue
	this.requests = [];
	// timer for next run
	this.coming_run = undefined;
	
	// rate limit in ms for Overpass API calls
	this.RATE_LIMIT = 100;
	this.current_rate_limit = this.RATE_LIMIT
	this.last_rate_error = null;
    }

    /**
     * @effect add request to queue, ensure queue runs
     */
    add(request){
	this.requests.unshift(request);
	this.ensureRunning();
    }

    /**
     * @effect ensure queue runs
     */
    ensureRunning(){
	if(!this.coming_run){
	    // set variable early for locking
	    // in multi-threaded JS, this should be a critical section
	    this.coming_run = true;
	    this.scheduleRun();
	}
    }

    /**
     * @effect schedule one run of queue (that may schedule more runs)
     */
    scheduleRun(){
	// update rate limit is needed
	if(this.last_rate_error){
	    this.current_rate_limit = Math.max(this.RATE_LIMIT, this.current_rate_limit - (Date.now() - this.last_rate_error) / 20);
	}

	// schedule
	if(this.last_call === undefined || Date.now() - this.last_call > this.current_rate_limit){
	    this.run();
	}
	else{
	    this.coming_run = window.setTimeout(()=>{
		this.run();
	    }, this.current_rate_limit - (Date.now() - this.last_call));
	}
    }

    /**
     * @effect execute one queue run, possibly sending a request
     */
    run(){
	if(this.requests.length){
	    var request = this.requests.pop();
	    if(request.cancelled){
		this.run();
	    }
	    else{
		this.last_call = Date.now();
		request.send(()=>{
		},()=>{
		    // increate wait time on rate limit error
		    this.current_rate_limit *= 2;
		    this.last_rate_error = Date.now();
		    this.add(request);
		});
		this.coming_run = window.setTimeout(()=>{
		    this.scheduleRun();
		}, this.current_rate_limit - (Date.now() - this.last_call));
	    }
	}
	else{
	    this.coming_run = null;
	}
    }
}

/**
 * Collection with all POI:s ,stored remote
 */
class POIsDB{

    constructor(){
	// queue for resolving requests
	this.queue = new RequestQueue();
    }

    /**
     * @effect call callback with found POIs matching query and extent
     *         call rate_warning_callback if throttled, error_callback on error
     */
    lookup(query, extent, callback, rate_warning_callback, error_callback){
	// get tags
	var tagGroups = new Set();
	for(var id of query.categories){
	    var category = categories.byID(id);
	    for(var tagGroup of category.allTagGroups()){
		tagGroups.add(tagGroup);
	    }
	    
	}

	// return if empty
	if(tagGroups.size == 0){
	    return []
	}
	
	// build query
	var data = "[out:json][timeout:25];\n";
	data +="(\n";
	for(var tagGroup of tagGroups){
	    data += 'nwr';
	    for(var tag of tagGroup){
		data += '["'+tag[0]+'"="'+tag[1]+'"]';
	    }
	    data+="("+extent[1]+","+extent[0]+","+extent[3]+","+extent[2]+");\n";
	}
	data +=");\n";
	data += "out geom;\n";

	// add request to queue
	var request = new Request({
	    url: "https://overpass-api.de/api/interpreter",
     	    dataType: "json",
	    data:{'data':data}
	}, query, callback, rate_warning_callback, error_callback);
	this.queue.add(request);
	return request;
    }
}

// ---- public objects ----

/**
 * public POIs database instance
 */
export var poisDB = new POIsDB();
