/**
 * @module
 * details widget
 */

import $ from 'jquery';
import {_, locale} from './locale.js';
import {Widget} from "./widget.js";

const KEYCODE_ESC = 27;

// ---- classes ----

/**
 * details widget
 */
export class Details extends Widget{

    constructor(mainDialog){
	super();

	// references to application objects
	this.mainDialog = mainDialog;
	
	this.poi = null;
	this.show_tags = false;

	// close keypress
	$(document).on("keyup.details",(e)=>{
	    if(e.keyCode == KEYCODE_ESC){
		this.close();
		return false;
	    }
	});
	
	// close button
	$("#details .closeButton").on("click",()=>{
	    this.close();
	    return false;
	})

	this.loadTemplates(['details']);
	this.updateIfReady();

	// expander for tags
	$("#details").on("click",".tagsButton .open",(e)=>{
	    this.showTags = true;
	    this.updateTagsVisibility();
	    return false;
	});
	$("#details").on("click",".tagsButton .close",(e)=>{
	    this.showTags = false;
	    this.updateTagsVisibility();
	    return false;
	});
    }
    
    /**
     * @effect show details of poi
     */
    show(poi){
	this.poi = poi;
	this.updateIfReady();
    }

    /**
     * @effect close dialog
     */
    close(){
	this.poi = null;
	$("#details").hide();
	$("#mainRow").removeClass("detailsOpen");
	this.mainDialog.saveWidth();
    }

    updateIfReady(){
	if(!this.poi){
	    return;
	}
	super.updateIfReady();
    }
    
    update(){
	var tags = {};
	for(var item of Object.entries(this.poi.tags)){
	    var key = item[0].replaceAll(/[^a-z0-9]/g,'_');
	    tags[key] = item[1];
	}

	const DAY_SHORT = {
	    'Mo' : 'day-mo',
	    'Tu' : 'day-tu',
	    'We' : 'day-we',
	    'Th' : 'day-th',
	    'Fr' : 'day-fr',
	    'Sa' : 'day-sa',
	    'Su' : 'day-su',
	};

	var opening_hours = null;
	if(tags.opening_hours){
	    opening_hours = []
	    for(var group_proto of tags.opening_hours.split(';')){
		var m = group_proto.match(/^\s*([\w\,\ \-]+)\s+(\d.*?)\s*$/);
		if(!m){
		    continue;
		}

		// replace days with localised string
		var days = m[1];
		for(var item of Object.entries(DAY_SHORT)){
		    days = days.replace(item[0],_(item[1]));
		}
		
		var group = {days:days, hours:m[2]}
		opening_hours.push(group);
	    }
	}

	var description = tags["description_" + locale.get_lang()] || tags["description"] || tags["lst_beskrivning"] || null;
	
	var html = this.templates.details.render({
	    tags:tags,
	    description: description,
	    opening_hours: opening_hours,
	    poi:this.poi,
	});
	$("#details .content").html(html);
	this.updateTagsVisibility();

	// prevent click directly after opening
	$("#details a").on("click.details",(e)=>{
	    e.stopPropagation();
	    e.preventDefault();
	});
	window.setTimeout(()=>{
	    $("#details a").off("click.details");
	},100);
	
	$("#details").show();
	$("#mainRow").addClass("detailsOpen");
	
	this.mainDialog.saveWidth();
    }

    /**
     * @effect: show or hide OSM tags
     */
    updateTagsVisibility(){
	if(this.showTags){
	    $("#details .tagsButton .open").hide();
	    $("#details .tagsButton .close").show();
	    $("#details .tags").show();
	}
	else{
	    $("#details .tagsButton .open").show();
	    $("#details .tagsButton .close").hide();
	    $("#details .tags").hide();
	};
    }
}
