/**
 * @module
 * translations registry
 *
 * @author Per Eric Rosén
 *         based on a module published in the UIP-I course by the same author
 */
 
import $ from 'jquery';
import {ChangeableMixin} from './changeable.js';

// --- public constants ----

/**
 * available UI languages
 */
export const languages = {
    'en':"english",
    'sv':"svenska",
};

// ---- classes ----

/**
 * translations registry
 */
class Translations extends ChangeableMixin(Object){
    constructor(){
	super(),

	// ready state
	this.ready = false;
	
	this.loadTranslations(languages);
    }

    // effect: load translations for languages langs
    loadTranslations(languages){
	
	this.translations = {};

	// translations currently loading
	var translationsLoading = new Set();
	// mark that we still add translations for loading
	var translationsLoop = true;
	
	for(var lang in languages){
	    translationsLoading.add(lang);
	    // ensure that each closure gets its own lang
	    ((lang)=>{
		$.ajax({
     		    dataType: "json",
     		    url:'locale/' + lang + '.json',
 		    error: (xhr, error, details)=>{
 			throw new Error("load error for translation " + lang + ": " + details);
     		    },
		    success: (json)=>{
			this.translations[lang] = json;
			translationsLoading.delete(lang);
			if(!translationsLoop && translationsLoading.size == 0){
			    this.ready = true;
			    this.onChanged();
			}
		    }
		});
	    })(lang);
	}

	// mark that all translation loads are started
	translationsLoop = false;
    }
    
    lookup(key, lang){
	return this.translations[lang][key] || this.translations["en"][key] || key;
    }
}

// ---- public objects ----

/**
 * public translations registry instance
 */
export var translations = new Translations();
