/**
 * @module
 * mixin for things that may change
 *
 * @author Per Eric Rosén
 *         based on a module published in the UIP-I course by the same author
 */

// ---- classes ----

/**
 * mixin for things that may change (both models and widgets)
 */
export const ChangeableMixin = (base) => class extends base {
    constructor(){
	super();
	
	// callbacks to call when changed
	this.onChangedCallbacks = {}
	// map of parents associated with callback
	this.changeableParents = new WeakMap();
	// distinguishing nr for callback-associated parent
	this.changeableParentsNr = 0;
    }
    
    /**
     * @effect register callback to call callback at parent at change
     * @return id of subscription
     */
    registerChangedCallback(parent, callback){
	if (!this.changeableParents.has(parent)) this.changeableParents.set(parent,++this.changeableParentsNr);
	var parentNr = this.changeableParents.get(parent);
	if(this.onChangedCallbacks[parentNr] === undefined){
	    this.onChangedCallbacks[parentNr] = [];
	}
	this.onChangedCallbacks[parentNr].push(callback);
    }

    /**
     * @effect deregister all callbacks at parent
     */
    deregisterChangedCallbacks(parent){
	var parentNr = this.changeableParents.get(parent);
	delete this.onChangedCallbacks[parentNr];
    }

    /**
     * @effect run code registered to run after change, after delay
     */
    delayedOnChanged(source, delay){
	if(this.delayedTimer){
	    window.clearTimeout(this.delayedTimer);
	    this.delayedTimer = null;
	}
	this.delayedTimer = window.setTimeout(()=>{
	    this.delayedTimer = null;
	    this.onChanged(source);
	},delay);
    }
    
    /**
     * @effect run code registered to run after change
     */
    onChanged(source){
	for(var [parentNr, callbacks] of Object.entries(this.onChangedCallbacks)){
	    for (var callback of callbacks){
		callback(source);
	    }
	}
    }
}
