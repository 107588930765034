/**
 * @module
 * main UI
 */

import $ from 'jquery';
import {ChangeableMixin} from './changeable.js';
import {_} from './locale.js';
import {Map} from './map.js';
import {Menu} from './menu.js';
import {Query} from './query.js';
import {Status} from './status.js';
import {Details} from './details.js';
import {LanguagePicker} from './languagePicker.js';
import {Widget} from './widget.js';

// ---- classes ----

/**
 * main UI
 */
export class MainDialog extends Widget{
    
    constructor(status){
	super();
	
	// references to application objects
	this.status = status;

	$(window).on("resize",()=>{
	    this.saveWidth();
	});
	
	this.updateIfReady();
    }
    
    /**
     * @effect: adjust variable --menu-with
     */
    saveWidth(){
	if($("#menu").is(":visible")){
	    var width = $("#menu").outerWidth();
 	}
	else{
	    var width = 0;
	}
	$("#mainRow").css('--menu-right', width + "px");

	if($("#details").is(":visible")){
	    var detailsRight = $("#details").offset().left + $("#details").outerWidth();
 	}
	else{
	    var detailsRight = width;
	}
	$("#mainRow").css('--dialog-right' ,detailsRight + "px");
    }

    update(){
	// set welcome status
	this.status.set(_("welcome-status"));

	// update static HTML with localised strings
	document.title = _('APP_TITLE');
	$('#appTitle').html(_('APP_TITLE'));
	$('#appTitleShort').html(_('APP_TITLE_SHORT'));
	$('#menuButton .menuText').html(_('MENU_TEXT'));

	this.saveWidth();
    }
}


// ---- main ----

// load widgets when HTML is loaded
$(window).on("load",()=>{
    var query=new Query();
    var languagePicker = new LanguagePicker();
    var status = new Status();
    var mainDialog = new MainDialog(status);
    var details = new Details(mainDialog);
    var map = new Map(query, status, details, mainDialog);
    var menu = new Menu(query, map, mainDialog);
});
