/**
 * @module
 * models for POI query
 */

import {ChangeableMixin} from './changeable.js';

// ---- classes ----

/**
 * POI query
 */
export class Query extends ChangeableMixin(Object){

    constructor(){
	super();

	// categories to search for
	this.categories = new Set();
    }
}

