/**
 * @module
 * base class for widgets
 *
 * @author Per Eric Rosén
 *         based on a module published in the UIP-I course by the same author
 */

import {TemplatesMixin} from "./templates.js";
import {locale} from './locale.js';

/**
 * Base widget
 * requirement on subclasses: run this.updateIfReady() in constructor
 * requirement on subclasses: implement update if needed
 */
class BaseWidget{

    constructor(){
	// dependencies in general (objects already existing)
	this.dependencies = [];
	// if update is in progress
	this.updateInProgress = false;
	
	// start with false ready status
	// do not try to update directly - subclass may add dependencies
	this.ready = false;
    }

    /**
     * @effect add dependency on d, add callback when d changes
     * @pre    d implements Changeable, and has "ready" property
     */
    addDependency(d){
	this.dependencies.push(d);
	d.registerChangedCallback(this, (source)=>{
	    this.updateIfReady();
	});
    }
    
    /**
     * @effect call update and set ready=true if dependencies are ready
     */
    updateIfReady(){
	// don't update if something changed from within update
	if(this.updateInProgress){
	    return;
	}
	
	// only update if regular dependencies are met
	for(var d of this.dependencies){
	    if(d.ready === false){
		return;
	    }
	}
	
	// init and update
	this.ready = true;
	this.updateInProgress = true;
	this.update();
	this.updateInProgress = false;
    }

    /**
     * @effect update widget view
     */
    update(){
    }
}

/**
 * Widget with locale dependency and templates functionality
 */
export class Widget extends TemplatesMixin(BaseWidget){
    constructor(){
	super()
	
	// always add dependency on locale
	this.addDependency(locale);
    }
}
