/**
 * @module
 * models for POI categories
 */

// ---- classes ----

/**
 * single category of POI
 */
class Category{

    constructor(id, tagGroups, contains, root){
	// symbolic name
	this.id = id;
	// groups or AND-joined OSM key-value tags to match
	this.tagGroups = tagGroups;
	// other categories included in this
    	this.contains = contains || [];
	// if suitable for top-level menu
	this.root = root || false;
    }

    // ---- general access ----

    /**
     * @return direct child elements of this
     */
    children(){
	var children = [];
	for(var subID of this.contains){
	    children.push(categories.byID(subID));
	}
	return children;
    }
    
    /**
     * @return all OSM tags from this or children
     */
    allTagGroups(){
	var tagGroups = new Set(this.tagGroups);
	for(var subID of this.contains){
	    tagGroups = new Set([...tagGroups, ...categories.byID(subID).allTagGroups()]);
	}
	return tagGroups;
    }

}

/**
 * categories DB
 */
class Categories extends Array{
    
    /**
     * @return    category by id
     * @exception new Error if id is not found
     */
    byID(id){
	for(var category of this){
	    if(category.id == id){
		return category;
	    }
	}
	throw new Error("Category with id " + id +" not found");
    }

    /**
     * @return    category matching tags, with id in ids
     * @exception new Error if id is not found
     */
    byTags(tags, ids){
	for(var category of this){
	    // only process selected ids (for example, ids used in query)
	    if(!ids.has(category.id)){
		continue;
	    }

	    // find category for tags
	    for(var tagGroup of category.tagGroups){
		var found = true;
		for(var tag of tagGroup){
		    if(tags[tag[0]] != tag[1]){
			found = false;
		    }
		}
		if(found){
		    return category;
		}
	    }
	}
	throw new Error("Category matching any of tags " + tags +" not found");
    }
}

// ---- public objects ----

/**
 * public categories DB instance
 */
export var categories = new Categories(
    // restaurants
    new Category('restaurant-group',[],['restaurant','pub','fast_food','cafe','ice_cream'],true),
    new Category('restaurant',[[['amenity','restaurant']],[['amenity','food_court']]],[],false),
    new Category('pub',[[['amenity','pub']],[['amenity','biergarten']]],[],false),
    new Category('fast_food',[[['amenity','fast_food']]],[],false),
    new Category('cafe',[[['amenity','cafe']]],[],false),
    new Category('ice_cream',[[['amenity','ice_cream']]],[],false),

    // food shops
    new Category('supermarket-group',[],['supermarket','convenience','greengrocer','dairy','farm','bakery','pastry'],true),
    new Category('supermarket',[[['shop','supermarket']]],[],false),
    new Category('convenience',[[['shop','convenience']]],[],false),
    new Category('greengrocer',[[['shop','greengrocer']]],[],false),
    new Category('dairy',[[['shop','dairy']],[['shop','cheese']]],[],false),
    new Category('farm',[[['shop','farm']]],[],false),
    new Category('bakery',[[['shop','bakery']]],[],false),
    new Category('pastry',[[['shop','pastry']]],[],false),

    // amenities
    new Category('amenity-group',[],['drinking_water','wc','shower','recycling','wlan'],true),
    new Category('drinking_water',[[['amenity','drinking_water']],[['amenity','water_point']],[['drinking_water','yes']]],[],false),
    new Category('wc',[[['amenity','toilets']]],[],false),
    new Category('shower',[[['amenity','shower']]],[],false),
    new Category('recycling',[[['amenity','recycling']]],[],false),
    new Category('wlan',[[['internet_access','wlan']],[['internet_access','yes']]],[],false),

    // swimming
    new Category('bath-group',[],['bathing_place','water_park'],true),
    new Category('bathing_place',[[['leisure','bathing_place']],[['amenity','public_bath']]],[],false),
    new Category('water_park',[[['leisure','water_park']],[['leisure','beach_resort']]],[],false),
    
    // camping
    new Category('camping-group',[],['tent_ground','camp_site-basic','camp_site','lean_to','basic_hut','hut','chalet','alpine_hut','hostel','guest_house','hotel'],true),
    new Category('tent_ground',[[['amenity','tent_ground']]],[],false),
    new Category('camp_site-basic',[[['tourism','camp_site'],['camp_site','basic']],[['tourism','camp_site'],['backcountry','yes']],[['tents','yes'],['backcountry','yes']]],[],false),
    new Category('camp_site',[[['tourism','camp_site']]],[],false),
    new Category('hut',[[['tourism','wilderness_hut']]],[],false),
    new Category('basic_hut',[[['shelter_type','basic_hut']]],[],false),
    new Category('lean_to',[[['shelter_type','lean_to']]],[],false),
    new Category('alpine_hut',[[['tourism','alpine_hut']]],[],false),
    new Category('chalet',[[['tourism','chalet']],[['cabins','yes']]],[],false),
    new Category('guest_house',[[['tourism','guest_house']]],[],false),
    new Category('hostel',[[['tourism','hostel']]],[],false),
    new Category('hotel',[[['tourism','hotel']],[['tourism','motel']]],[],false),

    // outdoor
    new Category('outdoor-group',[],['national_park','nature_reserve','garden','picnic','viewpoint','picnic_table','fire','bird_hide'],true),
    new Category('national_park',[[['boundary','national_park']]],[],false),
    new Category('nature_reserve',[[['leisure','nature_reserve']]],[],false),
    new Category('garden',[[['leisure','garden']]],[],false),
    new Category('viewpoint',[[['tourism','viewpoint']]],[],false),
    new Category('picnic',[[['tourism','picnic_site']]],[],false),
    new Category('picnic_table',[[['leisure','picnic_table']]],[],false),
    new Category('fire',[[['amenity','bbq']],[['leisure','firepit']],[['bbq','yes']],[['fireplace','yes']]],[],false),
    new Category('bird_hide',[[['leisure','bird_hide']]],[],false),

    // tourism
    new Category('tourism-group',[],['attraction','artwork','gallery','museum','archaeological_site','manor','castle','historic-citywall','historic-church'],true),
    new Category('archaeological_site',[[['historic','archaeological_site']]],[],false),
    new Category('manor',[[['historic','manor']]],[],false),
    new Category('castle',[[['historic','castle']]],[],false),
    new Category('historic-citywall',[[['historic','city_gate']],[['historic','citywalls']]],[],false),
    new Category('historic-church',[[['historic','church']],[['building','cathedral']],[['historic','monastery']]],[],false),
    new Category('artwork',[[['tourism','artwork']]],[],false),
    new Category('gallery',[[['tourism','gallery']]],[],false),
    new Category('museum',[[['tourism','museum']]],[],false),
    new Category('attraction',[[['tourism','attraction']]],[],false),
);
