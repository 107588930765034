/**
 * @module
 * HTML templates UI library
 *
 * @author Per Eric Rosén
 *         based on a module published in the UIP-I course by the same author
 */

import $ from 'jquery';
import {_} from './locale.js';

// ---- public mixin classes ----

/**
 * mixin for widgets using templates
 * @pre: has update()
 */
export const TemplatesMixin = (base) => class extends base {
    
    constructor(...args){
	super(...args);

	// templates loaded by this object
	this.templates = {};
    }

    /**
     * @effect              load templates, call updateIfReady
     *                      when templates are loaded
     * @param templateNames templates to load
     */
    loadTemplates(templateNames){
	if(this.templateLoadsPending === undefined){
	    this.templateLoadsPending = 0;
	}
	this.templateLoadsPending++;
	
	templatesDB.load(templateNames,(templates)=>{
	    if(this.templates === undefined){
		this.templates={};
	    }
	    for(var templateName of templateNames){
		this.templates[templateName] = templates[templateName];
	    }
	    this.templateLoadsPending--;
	    this.updateIfReady();
	});
    }
    
    updateIfReady(){
	// only update if templates are ready
	if(this.templateLoadsPending > 0){
	    return;
	}

	super.updateIfReady();
    }
}

// ---- common templates registry ----

/**
 * templates registry
 */
class TemplatesDB{

    constructor(){
	// all loaded tempates
	this.templates = {};
    }
    
    /**
     * @effect load templateNames, run onLoaded when done
     */
    load(templateNames, onLoaded){

	// context for loading templates
	var loadContext={
	    // templates to load
	    load:new Set(),
	    // templates currently loading
	    loading:new Set(),
	    // templates loaded
	    loaded:{},
	    onLoaded: onLoaded,
	}
	
	for(var templateName of templateNames){
	    loadContext.load.add(templateName);
	}
	for(var templateName of templateNames){
	    this.loadSingleTemplate(templateName, loadContext);
	}
    }

    /**
     * @effect load templateName, run load.onLoaded when all are loaded
     */
    loadSingleTemplate(templateName, loadContext){
	// ensure is listed as template to load
	loadContext.load.add(templateName);

	// skip if loading is already started
	if(loadContext.loading.has(templateName)){
	    return;
	}
	
	// check if already loaded
	if(this.templates[templateName]){
	    this.onLoaded(templateName, loadContext);
	    return;
	}

	// mark as loading
	loadContext.loading.add(templateName);

	// load with ajax
	$.ajax({
	    cache: false,
     	    dataType: "html",
     	    url:'html/' + templateName + '.html',
 	    error: (xhr, error, details)=>{
 		throw new Error("load error for template " + templateName + ": " + details);
     	    },
	    success: (html)=>{
		this.templates[templateName] = new Template(html, loadContext);
		this.onLoaded(templateName, loadContext);
	    }
	});
    }

    /**
     * @effect handle templateName being loaded
     */
    onLoaded(templateName, loadContext){

	// mark as loaded
	loadContext.load.delete(templateName);
	loadContext.loading.delete(templateName);
	loadContext.loaded[templateName] = this.templates[templateName];

	// run onLoaded if all is loaded
	if(loadContext.loading.size == 0){
	    loadContext.onLoaded(loadContext.loaded);
	}
    }
}

/**
 * private templates DB instance
 */
var templatesDB = new TemplatesDB();

// ---- data contexts for templates ----

/**
 * data context for templates
 */
export class TemplateContext{
    constructor(dict, parent=null){
	// dict defining context
	this.dict = dict;
	// parent context, if any
	this.parent = parent;
    }

    /**
     * @return key expanded in this context
     */
    expand(key){
	// for debug messages
	var origKey = key;

	// start with some value
	var value = undefined;
	var m;

	if(m = key.match(/^\"(.*?)\"/)){
	    // literal
	    value = m[1];
	    key = key.replace(m[0],'');
	}
	else if(m = key.match(/^(\d+)/)){
	    // numeric literal
	    value = parseInt(m[1]);
	    key = key.replace(m[0],'');
	}
	else if(m = key.match(/^(\w+)/)){
	    // read variable from context
	    value = this.lookup(m[1]);
	    if(value instanceof Function){
		value = value();
	    }
	    key = key.replace(m[0],'');
	}
	else{
	    throw new Error('variable expansion parse error for ' + key);
	}

	while(key.length > 0){

	    // property lookup
	    if(m = key.match(/^\.(\w+)/)){

		if(value[m[1]] instanceof Function){
		    value = value[m[1]]();
		}
		else{
		    value = value[m[1]]
		}
		
		key = key.replace(m[0],'');
		continue;
	    }

	    // check for array or set content
	    if(m = key.match(/^\|hascontent/)){
		value = (value && ((value.length || value.size) > 0));
		key = key.replace(m[0],'');
		continue;
	    }

	    // translation
	    if(m = key.match(/^\|trans/)){
		value = _(value)
		key = key.replace(m[0],'');
		continue;
	    }

	    // formatting
	    if(m = key.match(/^\|priceformat/)){
		value = value.toFixed(2);
		key = key.replace(m[0],'');
		continue;
	    }
	    if(m = key.match(/^\|floatformat:(\d+)/)){
		var decimals = parseInt(m[1]);
		value = value.toFixed(decimals);
		key = key.replace(m[0],'');
		continue;
	    }
	    if(m = key.match(/^\|first/)){
		value = value[0];
		key = key.replace(m[0],'');
		continue;
	    }
	    if(m = key.match(/^\|default:\"(.*?)\"/)){
		value = ((value === undefined || value === null) ? m[1] : value);
		key = key.replace(m[0],'');
		continue;
	    }
	    
	    // addition
	    if(m = key.match(/^\|add:(\d+)/)){
		value = value + parseInt(m[1]);
		key = key.replace(m[0],'');
		continue;
	    }
	    
	    throw new Error('variable expansion parse error for ' + key);
	}

	return value;
    }
    
    /**
     * @return value of key in this context of parent contexts
     */
    lookup(key){
	if(key in this.dict){
	    return this.dict[key];
	};
	if(this.parent){
	    return this.parent.lookup(key);
	}
	return undefined;
    }
}

// ---- nodes in template ----

/**
 * node in template
 */
class TextNode{
    constructor(html){
	// html text content
	this.html = html;
    }

    /**
     * @return html resulting from this node expanded in context
     */
    render(context){
	var html = this.html;
	
	var match;
	while(match = html.match(/\{\{\s*(.*?)\s*\}\}/)){
	    var key = match[1];
	    var value = context.expand(key);
	    html = html.replace(match[0], value);
	};
	
	return html;
    };
}

/**
 * node for blocktrans tag
 */
class BlocktransNode{
    constructor(content){
	// content
	this.content = content;
    }

    /**
     * @return html resulting from this node expanded in context
     */
    render(context){
	var html = this.content.reduce((existing, node) => existing + node.render(context), "");
	return _(html);
    };
}

/**
 * node for include tag
 */
class IncludeNode{
    constructor(template){
	// template to include
	this.template = template;
    }

    /**
     * @return html resulting from this node expanded in context
     */
    render(context){
	console.log(this.template);
	var template = context.expand(this.template);
	return template.renderWithContext(context);
    };
}

/**
 * node for with tag
 */
class WithNode{
    constructor(alias, value, content){
	// symbol to replace
	this.alias = alias;
	// value to look up in context
	this.value = value;
	// context to look up values in
	this.content = content;
    }

    /**
     * @return html resulting from this node expanded in context
     */
    render(context){
	var html = '';

	var v = context.expand(this.value);
	var subContext = new TemplateContext({[this.alias] : v}, context);
	html += this.content.reduce((existing, node) => existing + node.render(subContext), "");
	
	return html;
    };
}

/**
 * node for if tag
 */
class IfNode{
    constructor(condition, ifContent, elseContent){
	// conditional
	this.condition = condition;
	// content if true
	this.ifContent = ifContent;
	// content if false
	this.elseContent = elseContent;
    }

    /**
     * @return html resulting from this node expanded in context
     */
    render(context){
	var content;
	if(this.condition.render(context)){
	    content = this.ifContent;
	}
	else{
	    content = this.elseContent;
	}
	return content.reduce((existing, node) => existing + node.render(context), "");
    };
}

/**
 * node for for tag
 */
class ForNode{
    constructor(alias, iterable, loopContent, elseContent){
	// symbol to replace
	this.alias = alias;
	// things to iterate over
	this.iterable = iterable;
	// content to evaluate in loop
	this.loopContent = loopContent;
	// content to evaluate if loop is not run
	this.elseContent = elseContent;
    }

    /**
     * @return html resulting from this node expanded in context
     */
    render(context){
	var html = '';

	var found = false;
	for(var i of context.expand(this.iterable)){
	    found = true;
	    var subContext = new TemplateContext({[this.alias] : i}, context);
	    html += this.loopContent.reduce((existing, node) => existing + node.render(subContext), "");
	}
	if(!found){
	    html += this.elseContent.reduce((existing, node) => existing + node.render(context), "");
	}
	
	return html;
    };
}

// ---- logical expressions in template ----

/**
 * logical expression for basic value
 */
class ValueExpression{
    static level = 5;
    
    constructor(value){
	// value
	this.value = value;
    }

    /**
     * @return value resulting from this expression expanded in context
     */
    render(context){
	return context.expand(this.value);
    }
}

/**
 * logical expression for in operator
 */
class InExpression{
    static symbol = 'in';
    static level = 4;
    
    constructor(e1, e2){
	// value to look for
	this.e1 = e1;
	// value to look into
	this.e2 = e2;
    }

    /**
     * @return value resulting from this expression expanded in context
     */
    render(context){
	var v1 = this.e1.render(context);
	var v2 = this.e2.render(context);
	return (v2.has && v2.has(v1)) || v1 in v2 || (v2.includes && v2.includes(v1));
    }
}

/**
 * logical expression for not operator
 */
class NotExpression{
    static symbol = 'not';
    static level = 3;

    constructor(e1){
	// content
	this.e1 = e1;
    }

    /**
     * @return value resulting from this expression expanded in context
     */
    render(context){
	var v1 = this.e1.render(context);
	return !v1;
    }
}

/**
 * logical expression for equality operator
 */
class EqualExpression{
    static symbol = '==';
    static level = 2;

    constructor(e1, e2){
	// content to compare
	this.e1 = e1;
	this.e2 = e2;
    }

    /**
     * @return value resulting from this expression expanded in context
     */
    render(context){
	var v1 = this.e1.render(context);
	var v2 = this.e2.render(context);
	return v1 == v2;
    }
}

/**
 * logical expression for less than operator
 */
class LessThanExpression{
    static symbol ='<';
    static level = 2;
    
    constructor(e1, e2){
	// content to compare
	this.e1 = e1;
	this.e2 = e2;
    }

    /**
     * @return value resulting from this expression expanded in context
     */
    render(context){
	var v1 = this.e1.render(context);
	var v2 = this.e2.render(context);
	return v1 < v2;
    }
}

/**
 * logical expression for less than or equal operator
 */
class LessThanEqualExpression{
    static symbol ='<=';
    static level = 2;
    
    constructor(e1, e2){
	// content to compare
	this.e1 = e1;
	this.e2 = e2;
    }

    /**
     * @return value resulting from this expression expanded in context
     */
    render(context){
	var v1 = this.e1.render(context);
	var v2 = this.e2.render(context);
	return v1 <= v2;
    }
}

/**
 * logical expression for greater than operator
 */
class GreaterThanExpression{
    static symbol ='>';
    static level = 2;
    
    constructor(e1, e2){
	// content to compare
	this.e1 = e1;
	this.e2 = e2;
    }

    /**
     * @return value resulting from this expression expanded in context
     */
    render(context){
	var v1 = this.e1.render(context);
	var v2 = this.e2.render(context);
	return v1 > v2;
    }
}

/**
 * logical expression for greater than or equal operator
 */
class GreaterThanEqualExpression{
    static symbol ='>=';
    static level = 2;
    
    constructor(e1, e2){
	// content to compare
	this.e1 = e1;
	this.e2 = e2;
    }

    /**
     * @return value resulting from this expression expanded in context
     */
    render(context){
	var v1 = this.e1.render(context);
	var v2 = this.e2.render(context);
	return v1 >= v2;
    }
}

/**
 * logical expression for and operator
 */
class AndExpression{
    static symbol ='and';
    static level = 1;
    
    constructor(e1, e2){
	// content to compare
	this.e1 = e1;
	this.e2 = e2;
    }

    /**
     * @return value resulting from this expression expanded in context
     */
    render(context){
	var v1 = this.e1.render(context);
	if(!v1){
	    return false;
	}
	var v2 = this.e2.render(context);
	if(v2){
	    return v2;
	}
	return false;
    }
}

/**
 * logical expression for or operator
 */
class OrExpression{
    static symbol ='or';
    static level = 0;
    
    constructor(e1, e2){
	// content to compare
	this.e1 = e1;
	this.e2 = e2;
    }

    /**
     * @return value resulting from this expression expanded in context
     */
    render(context){
	var v1 = this.e1.render(context);
	if(v1){
	    return v1;
	}
	var v2 = this.e2.render(context);
	if(v2){
	    return v2;
	}
	return false;
    }
}

/**
 * private constants for expressions
 */ 
const prefixExpressions = [NotExpression];

/**
 * private constants for expressions
 */ 
const infixExpressions = [InExpression, EqualExpression, LessThanExpression, LessThanEqualExpression, GreaterThanExpression, GreaterThanEqualExpression, AndExpression, OrExpression];

// ---- templates ----

/**
 * template
 */
export class Template{
    constructor(html, loadContext){
	// HTML text
	this.html = html;
	// parsed content
	this.content = this.parse(html, loadContext)[0];
    }

    /**
     * @return parse tree of logical expression ex
     */
    parseExpression(ex){
	var tokens = ex.split(/\s+/);
	return this.parseExpressionTokens(tokens);
    }

    /**
    * @return  parse tree of logical expression from tokens
    *         consumes operators of priority level or higher
    * @effect shifts elements from tokens
    */
    parseExpressionTokens(tokens, level=0){

	// expression priority levels:
	// 0 or
	// 1 and
	// 2 < <= == => >
	// 3 not
	// 4 in
	// 5 value expressions

	// parse 
	if(tokens.length == 0){
	    throw new Error('expected start of expression, got empty tokens list');
	}
	
	var expression = undefined;
	var token = tokens.shift();
	var tk = this.tokenClass(token);

	initialToken:{
	    if(tk == ValueExpression){
		expression = new tk(token);
		break initialToken;
	    }

	    for(var k of prefixExpressions){
		if(tk == k){
		    expression = new tk(this.parseExpressionTokens(tokens, k.level + 1));
		    break initialToken;
		}
	    }
	}

	if(expression === undefined){
	    throw new Error('unexpected start token ' + token);
	}

	infix: while(tokens.length > 0){
	
	    token = tokens[0];
	    tk = this.tokenClass(token);

	    if(infixExpressions.includes(tk)){
		if(level > tk.level){
		    return expression;
		}
		else{
		    tokens.shift();
		    expression = new tk(expression, this.parseExpressionTokens(tokens, tk.level + 1));
		    continue infix;
		}
	    }
	    
	    throw new Error('unexpected token ' + token);
	}

	return expression;
    }

    /**
     * @return expression class for token
     */
    tokenClass(token){
	for(var tk of [...prefixExpressions, ...infixExpressions]){
	    if(token == tk.symbol){
		return tk;
	    }
	}
	return ValueExpression;
    }
    
    /**
     * @return (parse tree of tags in html, matched end tag, rest)
     *         looking for endTags
     */ 
    parse(html, loadContext, endTags=[]){

	var match;
	var nodes=[];

	// remove comments
	html = html.replace(/\{\#.+?\#\}/g,"");
	
	// parse tags
	while(match = html.match(/^(.*?)\{\%\s*(.*?)\s*\%\}(.*)$/s)){
	    var [before, tag, after] = match.slice(1,4);
	    var m;

	    // initial text node
	    if(before != ""){
		nodes.push(new TextNode(before));
	    };

	    // return on desired end tag
	    if(endTags.includes(tag)){
		return [nodes, tag, after];
	    }
	    
	    // blocktrans tag
	    if(tag == "blocktrans"){
		var content;
		var endTag;
		[content, endTag, html] = this.parse(after, loadContext, ['endblocktrans']);
		nodes.push(new BlocktransNode(content));
		continue;
	    }

	    // include tag
	    if(m = tag.match(/^include\s+\"(.+)\"$/)){
		html = after;
		templatesDB.loadSingleTemplate(m[1], loadContext);
		nodes.push(new IncludeNode("templates." + m[1]));
		continue;
	    }
	    if(m = tag.match(/^include\s+(.+)$/)){
		html = after;
		nodes.push(new IncludeNode(m[1]));
		continue;
	    }

	    // with tag
	    if(m = tag.match(/^with\s+(\w+)\=(.+)$/)){
		var content;
		var endTag;
		[content, endTag, html] = this.parse(after, loadContext, ['endwith']);
		nodes.push(new WithNode(m[1], m[2], content));
		continue;
	    }
	    
	    // if tag
	    if(m = tag.match(/^if\s+(.+)$/)){
		var ifContent;
		var endTag;
		[ifContent, endTag, html] = this.parse(after, loadContext, ['else', 'endif']);

		var elseContent;
		if(endTag == 'else'){
		    [elseContent, endTag, html] = this.parse(html, loadContext, ['endif']);
		}
		else{
		    elseContent = [];
		}
		nodes.push(new IfNode(this.parseExpression(m[1]), ifContent, elseContent));
		continue;
	    }

	    // for tag
	    if(m = tag.match(/^for\s+(\w+)\s+in\s+(.+)$/)){
		var loopContent;
		var endTag;
		[loopContent, endTag, html] = this.parse(after, loadContext, ['else', 'endfor']);

		var elseContent;
		if(endTag == 'else'){
		    [elseContent, endTag, html] = this.parse(html, loadContext, ['endfor']);
		}
		else{
		    elseContent = [];
		}
		nodes.push(new ForNode(m[1], m[2], loopContent, elseContent));
		continue;
	    }
	    
	    throw new Error('unexpected block tag ' + tag);
	}

	// final text node
	if(html != ""){
	    nodes.push(new TextNode(html));
	}

	// return tree from all html
	return [nodes, null, ""];
    }
    
    /**
     * @return template rendered with strings in dict replaced
     */
    render(dict){
	var context = new TemplateContext(dict, new TemplateContext({
	    templates:templatesDB.templates,
	    "true":true,
	    "false":false,
	}));
	return this.renderWithContext(context);
    }

    // @return template rendered in context
    renderWithContext(context){
	var html = this.content.reduce((existing, node) => existing + node.render(context), "");
	return html;
    }
}
