/**
 * @module
 * status widget
 */

import $ from 'jquery';
import {Widget} from "./widget.js";

// ---- classes ----

/**
 * status widget
 */
export class Status extends Widget{

    constructor(){
	super();
	// timer to hide status
	this.statusTimer=null;
	// type of message, useful for selective deactivation
	this.type=null;
    }
    
    /**
     * @effect     set status to message, possibly with specific timeout
     * @param type type of message, useful for selective deactivation
     */
    set(message, timeout=5000, type=null){
	this.type = type;
	
	var self = this;
	var s =$("#status");
	
	if(message){
	    s.show();
	    s.html(message);
	    if(timeout){
		if(this.statusTimer){
		    window.clearTimeout(this.statusTimer);
		};
		this.statusTimer=window.setTimeout(()=>{
		    self.set(null);
		},timeout);
	    };
	}
	else{
	    s.fadeOut(1000,function(){s.html("")});
	}
    }
}
