/**
 * @module
 * language picker widget
 *
 * @author Per Eric Rosén
 *         based on a module published in the UIP-I course by the same author
 */

import $ from 'jquery';

import {languages} from './translations.js';
import {_, locale} from './locale.js';
import {Widget} from './widget.js';

// ---- classes ----

/**
 * language picker widget
 */
export class LanguagePicker extends Widget{

    constructor(){
	super();
	
	var self = this;
	
	// one-time setup
	$("#languagePicker").on('click','.language',(e)=>{
	    e.preventDefault();
	    var lang = $(e.currentTarget).data('lang');
	    locale.lang = lang;
	    locale.onChanged();
	});

	this.loadTemplates(['languagePicker']);
	this.updateIfReady();
    }

    update(){
	$("#languagePicker").html(this.templates.languagePicker.render({
	    'languages': Object.entries(languages).map((e)=>{return {name:e[0],title:e[1]}})
	}));
    }
}
