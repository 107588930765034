/**
 * @module
 * locale UI
 *
 * @author Per Eric Rosén
 *         based on a module published in the UIP-I course by the same author
 */

import $ from 'jquery';
import {ChangeableMixin} from './changeable.js';
import {translations} from './translations.js';

// ---- public functions ----

/**
 * @return string translated to current user language
 */
export function _(key,dict=null){
    var text = translations.lookup(key,locale.get_lang());
    if(dict){
	for(const [key, value] of Object.entries(dict)){
	    text = text.replace('{{'+key+'}}',value);
	}
    }
    return text;
};

// ---- classes ----

/**
 * locale (mostly for signaling that locale is ready)
 */ 
class Locale extends ChangeableMixin(Object){
    
    constructor(){
	super();

	// ready status
	this.ready = false;
	// current language
	this.lang = undefined;

	// register dependency on translations and sessions
	translations.registerChangedCallback(this,(source)=>{
	    this.ready = true;
	    this.onChanged();
	});
    }

    /**
     * @return language to use in UI
     */
    get_lang(){
	if(this.lang){
	    return this.lang;
	}
	else{
	    return navigator.language.replace(/\-.*/,'');
	}
    }
}

// ---- public objects ----

/**
 * public locale object (mostly for signaling that locale is ready)
 */
export var locale = new Locale();
