/**
 * @module
 * menu and menu button widget
 */

import $ from 'jquery';
import {_} from './locale.js';
import {categories} from './categories.js';
import {Widget} from "./widget.js";

// ---- classes ----

/**
 * menu and menu button widget
 */
export class Menu extends Widget{
    
    constructor(query, map, mainDialog){
	super();
	
	// references to application objects
	this.query = query;
	this.map = map;
	this.mainDialog = mainDialog;
	
	var self=this;

	// estimate of full menu width (for swiping)
	this.menuWidth = 400;

	// swipe handler
	this.swipe = null;
	$("#menu").on("mousedown touchstart",(e)=>{
	    this.swipe={
		startX:e.type == 'mousedown' ? e.originalEvent.x : e.originalEvent.touches[0].pageX,
		startY:e.type == 'mousedown' ? e.originalEvent.y : e.originalEvent.touches[0].pageY,
		width:$("#menu").width(),
		active:false,
	    };
	    if($("#menu .expander").is(":visible")){
		this.swipe.maxWidth = $("#menu").width();
		this.swipe.breakpoint = $("#menu").width() - 50;
		this.swipe.direction = -1;
	    }
	    else{
		this.swipe.maxWidth = this.menuWidth;
		this.swipe.breakpoint = $("#menu").width() + 50;
		this.swipe.direction = 1;
	    }

	    $(document).on("mousemove.menuSwipe touchmove.menuSwipe",(e)=>{
		if(!this.swipe){
		    return;
		}
		this.swipe.x = e.type == 'mousemove' ? e.originalEvent.x : e.originalEvent.touches[0].pageX;
		this.swipe.y = e.type == 'mousemove' ? e.originalEvent.y : e.originalEvent.touches[0].pageY;
		if((this.swipe.x - this.swipe.startX) * this.swipe.direction > 20 && (Math.abs(this.swipe.startY - this.swipe.y) < Math.abs(this.swipe.startX - this.swipe.x))){
		    this.swipe.active = true;
		    $("#menu").addClass("swiping");
		    if(this.swipe.direction > 0){
			$("#menu").addClass("swipingIn");
		    }
		    else{
			$("#menu").addClass("swipingOut");
		    }
		    $("#menu").addClass("short");
		    $("#menu").removeClass("long");
		    var width = Math.min(Math.max((this.swipe.width - (this.swipe.startX - this.swipe.x)), 0), this.swipe.maxWidth);
		    $("#menu").css("min-width", width + "px");
		}
	    });
	    $(document).on("mouseup.menuSwipe touchend.menuSwipe touchcancel.menuSwipe",(e)=>{
		if(this.swipe.active){
		    e.preventDefault();
		    if(this.swipe.x > this.swipe.breakpoint){
			this.open();
		    }
		    else{
			this.short();
		    }
		    $("#menu").removeClass("swiping");
		    $("#menu").removeClass("swipingIn");
		    $("#menu").removeClass("swipingOut");
		    $("#menu").css("min-width", "");
		    this.mainDialog.saveWidth();
		}
		this.swipe = null;
		$(document).off(".menuSwipe");
	    });
	    
	});

	$("#menu").on("dragstart","a",(e)=>{
	    e.preventDefault();
	});
	
	// menu button to open and close menu
	$("#menuButton .open").on("click",()=>{
	    this.open();
	    return false;
	})
	$("#menuButton .close").on("click",()=>{
	    this.short();
	    return false;
	})

	// gps button
	$("#gpsMenu .on").on("click",(e)=>{
	    $("#gpsMenu .on").hide();
	    $("#gpsMenu .off").show();
	    this.map.stopGeoLocation();
	    return false;
	});
	$("#gpsMenu .off").on("click",(e)=>{
	    $("#gpsMenu .off").hide();
	    $("#gpsMenu .on").show();
	    this.map.startGeoLocation();
	    return false;
	});

	// expander buttons for categories (does not change map display)
	$("#categoriesMenu").on("click",".expander .open",(e)=>{
	    var div =  $(e.target).closest(".category");
	    this.expandCategory(div);
	    this.menuWidth = $("#menu").width();
	    return false;
	});
	$("#categoriesMenu").on("click",".expander .close",(e)=>{
	    var div =  $(e.target).closest(".category");
	    this.deexpandCategory(div);
	    this.menuWidth = $("#menu").width();
	    return false;
	});

	// toggle buttons for categories
	$("#categoriesMenu").on("click",".categoryButton",(e)=>{
	    var div =  $(e.target).closest(".category");
	    var id = $(div).data("category");
	    var category = categories.byID(id);
	    if(this.swipe){
		return false;
	    };

	    if($(e.currentTarget).hasClass("selected")){
		$(e.currentTarget).removeClass("selected");
		if(category.root){
		    // deactivate subcategories
		    for(var subcategory of category.children()){
			$("[data-category="+subcategory.id+"] .categoryButton").removeClass("selected");
			this.query.categories.delete(subcategory.id);
		    }
		    
		    // de-expand
		    this.deexpandCategory(div);
		}
		else{
		    this.query.categories.delete(id);
		    // ensure parent selected if and only if > 0 child selected
		    this.ensureParentSelected(div);
		}
		this.query.delayedOnChanged(this, 1000);
	    }
	    else{
		$(e.currentTarget).addClass("selected");
		if(category.root){
		    // activate subcategories
		    for(var subcategory of category.children()){
			$("[data-category="+subcategory.id+"] .categoryButton").addClass("selected");
			this.query.categories.add(subcategory.id);
		    }
		    
		    // expand
		    this.expandCategory(div);
		}
		else{
		    this.query.categories.add(id);
		    // ensure parent selected if and only if > 0 child selected
		    this.ensureParentSelected(div);
		}
		this.query.delayedOnChanged(this, 1000);
	    }

	    return false;
	})

	this.loadTemplates(['categoryMenu']);
	this.updateIfReady();
    }

    /**
     * @effect open menu, adjust button for open menu
     */
    open(){
	$("#mainRow").addClass("menuOpen");
	
	$("#menuButton .open").hide();
	$("#menuButton .close").show();
	$("#menuButton .close").css("display","inline");

	$("#menu").addClass("long");
	$("#menu").removeClass("short");
	$("#menu").show();
	this.menuWidth = $("#menu").width();
	this.mainDialog.saveWidth();
    }

    /**
     * @effect make menu short, adjust button for closed menu
     */
    short(){
	$("#mainRow").removeClass("menuOpen");

	$("#menuButton .close").hide();
	$("#menuButton .open").show();
	$("#menuButton .open").css("display","inline");

	$("#menu").addClass("short");
	$("#menu").removeClass("long");
	$("#menu").show();
	this.mainDialog.saveWidth();
    }
    
    /**
     * @effect close menu completely, adjust button for closed menu
     */
    close(){
	$("#mainRow").removeClass("menuOpen");
	
	$("#menuButton .close").hide();
	$("#menuButton .open").show();
	$("#menuButton .open").css("display","inline");

	$("#menu").removeClass("short");
	$("#menu").removeClass("long");
	$("#menu").hide();
	this.mainDialog.saveWidth();
    }

    /**
     * @effect expand category in div
     */
    expandCategory(div){
	$(".expander .open", div).hide();
	$(".expander .close", div).show();
	div.next(".subcategories").show();
    }

    /**
     * @effect deexpand category in div
     */
    deexpandCategory(div){
	$(".expander .close", div).hide();
	$(".expander .open", div).show();
	div.next(".subcategories").hide();
    }
    
    /**
     * @effect ensure div parent is selected if and only if > 0 child selected
     */
    ensureParentSelected(div){
	var subcategories=$(div).closest('.subcategories');
	var parent=$(subcategories).prev('.category');
	if($(".categoryButton.selected",subcategories).length > 0){
	    $(".categoryButton",parent).addClass("selected");
	}
	else{
	    $(".categoryButton",parent).removeClass("selected");
	}
    }

    update(){
	// update static HTML with localised strings
	document.title = _('APP_TITLE');
	$('#gpsMenu .on .text').html(_('GPX_ON'));
	$('#gpsMenu .off .text').html(_('GPX_OFF'));
	
	// update categories
	$('#categoriesMenu').html('');
	for(var category of categories){
	    
	    if(category.root){
		var children_html = [];
		for(var child of category.children()){
		    children_html.push(this.templates.categoryMenu.render({
			'category':child,
			'selected':this.query.categories.has(child.id),
		    }));
		}
		
		var html = this.templates.categoryMenu.render({
		    'category':category,
		    'selected':this.query.categories.has(category.id),
		    'children_html':children_html,
		});
		$('#categoriesMenu').append(html);
	    }
	}
	$("#categoriesMenu img").on("load",()=>{
	    this.mainDialog.saveWidth();
	});

	if($("#menu .expander").is(":visible")){
	    this.menuWidth = $("#menu").width();
	}
	this.mainDialog.saveWidth();
    }
}
